import { useSelector } from 'react-redux';
import OrganizationsModal from '../organizations-modal';
import Sidebar from '../sidebar';
import TopHeader from '../top-header';
import { FeedbackModal } from '../feedback-modal';
import { useEffect, useState } from 'react';
import { adminAPIs } from '../../services/adminAPIs';
import { useLocation } from 'react-router-dom';
import Button from '../button';
import { ReactComponent as CloseIcon } from '../../icons/closeIcon.svg';

export default function GeneralLayout({ children, mainStyle, noPadding, fullWidth, fixHeight }) {
  const [isServerProblems, setIsServerProblems] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === '/') {
      const fetchServerStatus = async () => {
        await adminAPIs
          .getTechStatus()
          .then((res) => setIsServerProblems(res?.status))
          .catch((err) => {
            console.error(err);
          });
      };

      fetchServerStatus();
    }
  }, [pathname]);
  const showOrganizationModal = useSelector((state) => state.organizations.showSelectModal);
  return (
    <div
      className="tw-flex tw-h-screen tw-overflow-hidden"
      id="top"
      style={{
        zIndex: 1,
        backgroundImage: "url('/images/background/background.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      <Sidebar />
      <div
        className="tw-relative tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-overflow-x-hidden"
        style={{ zIndex: 1 }}
      >
        <TopHeader isServerError={isServerProblems} />

        <main style={{ height: fixHeight && '100%', ...mainStyle }}>
          <div
            className={`${
              noPadding ? '' : 'tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-pb-8 tw-pt-[78px] sm:tw-pt-[100px] lg:tw-pt-8'
            } tw-w-full ${fullWidth ? '' : 'tw-max-w-9xl'} tw-mx-auto tw-relative`}
            style={{
              minHeight: '100vh',
              maxHeight: fixHeight ? 'calc(100vh - 4rem)' : 'auto',
              overflowY: fixHeight ? 'scroll' : 'auto',
            }}
          >
            {isServerProblems && (
              <>
                <div className="tw-flex tw-flex-row tw-w-full sm:tw-items-center tw-items-start tw-bg-[#FFE3E3] tw-rounded-[9px] tw-gap-[10px] tw-py-[14px] tw-px-5 tw-mb-9 tw-justify-between">
                  <p className="tw-text-[#FF4343] tw-leading-5 tw-font-medium">
                    Сервис у некоторых пользователей может работать с ошибками. Идут работы по обновлению. Приносим свои
                    извинения.
                  </p>
                  <Button
                    variant="text"
                    className="tw-w-fit tw-p-0 hover:tw-bg-transparent"
                    onClick={() => setIsServerProblems(false)}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              </>
            )}
            {children}
          </div>
        </main>
      </div>
      {showOrganizationModal && <OrganizationsModal />}
      <FeedbackModal />
    </div>
  );
}
