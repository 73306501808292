import React, { useCallback, useMemo, useState } from 'react';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { ReactComponent as CheckIcon } from '../../../../icons/greenCheckIcon.svg';
import { ReactComponent as TooltipIcon } from '../../../../icons/tooltipIcon.svg';
import { ReactComponent as ArrowIcon } from '../../../../icons/arrowRightIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../icons/deleteIcon.svg';
import { calculateProductOrderNumber, filterSourcesByType, getWordEnding1 } from '../../../../utils/common';
import { Collapse } from '@mui/material';
import Button from '../../../../components/button';
import styles from './productTable.module.sass';
import { PriceGraph } from '../../PriceGraph/PriceGraph';
import { SourcesList } from '../SourcesList/SourcesList';
import { ProductTableSkeleton } from '../Skeletons/ProductTableSkeleton';
import { ChangeUnit } from '../ChangeUnit/ChangeUnit';
import { useSelector } from 'react-redux';
import { useDialog } from '../../../../providers/dialog.provider';
import {
  useCancelProductSearchMutation,
  useDeleteProductMutation,
  useReloadProductMutation,
  useUpdateProductMutation,
} from '../../../../services';
import { createNotification } from '../../../../utils/notifications';
import { ConfirmModal } from '../../../../components/modal/ConfirmModal';
import classNames from 'classnames';
import { priceFormatter } from '../../../../utils/priceFormatter';
import { PRODUCT_LOADING_STATES, PRODUCT_STATUS } from '../../../../utils/constant';
import { AmountToggle } from '../../../../components/product-card/components/AmountToggle';
import { useUrlState } from '../../../../hooks/useUrlState';

export const ProductsTable = React.memo(({ rows }) => {
  return (
    <div className="tw-w-full tw-border tw-rounded-lg tw-overflow-hidden ">
      <table className="tw-w-full tw-rounded-t-lg tw-border-collapse">
        <thead className="tw-text-[#666666] tw-table-auto tw-text-sm tw-leading-[18px] tw-bg-white tw-rounded-t-lg tw-border-b tw-border-b-[#EEEEEE]">
          <tr>
            <th className="tw-rounded-tl-lg"></th>
            <th className="tw-pt-[19px] tw-pb-[17px]">Название товара</th>
            <th className="">Кол-во</th>
            <th className="tw-text-right">Средняя цена</th>
            <th className="tw-py-[11px] tw-text-right">
              <p className="tw-max-w-[77px] tw-inline-flex tw-justify-end">Сред. цена логистики</p>
            </th>
            <th className="tw-rounded-tr-lg"></th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows?.map((row, index) => <ProductsTableRow product={row} key={row?.id} productIndex={index} />)
          ) : (
            <div className="tw-flex tw-flex-row tw-items-center tw-w-full tw-h-full tw-justify-center tw-text-center ">
              Товары отсутствуют
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
});

const ProductsTableRow = ({ product, productIndex }) => {
  const [isSourcesOpen, setIsSourcesOpen] = useState(false);
  const isLoading = PRODUCT_LOADING_STATES.includes(product?.status);

  const report = useSelector((state) => state?.organizations?.selectedReport);
  const dialog = useDialog();

  const { getQueryParam } = useUrlState();

  const page_size = Number(getQueryParam('page_size')) || 10;
  const page_num = Number(getQueryParam('page_num')) || 1;

  const [updateProduct] = useUpdateProductMutation();
  const [deleteProduct] = useDeleteProductMutation();
  const [reloadProduct] = useReloadProductMutation();
  const [stopProductSearch] = useCancelProductSearchMutation();

  const handleChangeAmount = useCallback(
    async (value) => {
      await updateProduct({
        report_id: report?.id,
        product_id: product?.id,
        body: {
          amount: Number(value),
        },
      });
    },
    [product?.id, report?.id, updateProduct]
  );

  const handleUpdateProduct = useCallback(
    async (body) => {
      const report_id = product?.report_id;
      const product_id = product?.id;
      await updateProduct({ report_id, product_id, body });
    },
    [product?.id, product?.report_id, updateProduct]
  );

  const handleReloadProduct = async () => {
    await reloadProduct({ report_id: report?.id, product_id: product?.id });
  };

  const handleStopProductSearch = useCallback(async () => {
    await stopProductSearch(product?.id)
      .unwrap()
      .then(() => {
        createNotification('Поиск товара остановлен');
      });
  }, [product?.id, stopProductSearch]);

  const handleDeleteProduct = useCallback(async () => {
    await deleteProduct({ report_id: report?.id, product_id: product?.id });
  }, [deleteProduct, product?.id, report?.id]);

  const showDeleteProductModal = useCallback(() => {
    dialog.open(
      <ConfirmModal title={`Вы действительно хотите удалить товар ${product?.name}?`} onAgree={handleDeleteProduct} />
    );
  }, [dialog, handleDeleteProduct, product?.name]);

  const isProductEmpty = useMemo(() => {
    const projectIsNotSearching =
      product.status === PRODUCT_STATUS.FINISHED || product.status === PRODUCT_STATUS.CANCELED;
    if (product?.sources?.length === 0 && projectIsNotSearching) {
      return true;
    }
    return false;
  }, [product.sources.length, product.status]);

  const sourcesToDisplay = useMemo(() => {
    return product?.sources ? filterSourcesByType(product.sources, ['yandex_search', 'engine_search']) : [];
  }, [product?.sources]);
  return (
    <>
      {isLoading ? (
        <>
          <ProductTableSkeleton number={product?.number} handleStopSearch={handleStopProductSearch} />
        </>
      ) : (
        <>
          <tr
            className={`${
              isSourcesOpen ? 'tw-bg-[#f7f7f7]' : 'tw-bg-white tw-border-b'
            } tw-transition-colors tw-border-t  tw-border-[#EEEEEE]`}
          >
            <td className="tw-pl-3">
              <p className="tw-min-w-[30px] tw-text-[#7F7F7F] tw-text-xs tw-leading-[16px]">
                {calculateProductOrderNumber(productIndex, page_num, page_size)}
              </p>
            </td>
            <td className="tw-pr-[10px] tw-py-5 tw-max-w-[343px]">
              <div className="tw-flex tw-flex-row tw-justify-between tw-gap-2 tw-items-start tw-line-clamp-3 ">
                <p className={`${styles.productName} tw-text-[#191919] tw-text-sm tw-leading-[18px]`}>
                  {product?.name}
                </p>
                {product?.use_gpt && <CheckIcon className="tw-min-w-5 tw-min-h-5" />}
              </div>
            </td>
            <td>
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-3">
                <AmountToggle currentAmount={product?.amount} handleChange={handleChangeAmount} isSimplified />
                <ChangeUnit currentUnit={product?.unit} onChange={handleUpdateProduct} asTable />
              </div>
            </td>
            {product?.sources.length > 0 ? (
              <>
                <td>
                  <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-justify-end">
                    <p
                      className={classNames('tw-text-sm tw-leading-[18px] tw-font-medium tw-text-right', {
                        'tw-text-[#C12026]': product?.sources_with_incorrect_dimensions > 0,
                        'tw-text-[#191919]': product?.sources_with_incorrect_dimensions <= 0,
                      })}
                    >
                      {priceFormatter(product?.avg_price ?? 0, 2)}{' '}
                      <span className="tw-text-[10px] tw-leading-[13px]">₽</span>
                    </p>
                    <Tooltip title="Средняя цена за товар по ценам, найденным в источниках">
                      <TooltipIcon />
                    </Tooltip>
                  </div>
                </td>
                <td>
                  <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-justify-end">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium tw-text-right">
                      {priceFormatter(product?.avg_price ?? 0, 2)}
                      <span className="tw-text-[10px] tw-leading-[13px]">₽</span>
                    </p>
                    <Tooltip title="Список найденных транспортных компаний с ценами за услуги по доставке товара.">
                      <TooltipIcon />
                    </Tooltip>
                  </div>
                </td>
              </>
            ) : (
              <td colSpan={2}>
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-start">
                  <Button variant="text" onClick={handleReloadProduct}>
                    Запросить цену
                  </Button>
                </div>
              </td>
            )}
            <td className="tw-max-w-[96px] tw-pl-[14px]">
              <div className="tw-flex tw-flex-row tw-gap-3 tw-items-center tw-w-fit">
                <Button
                  variant="outlined"
                  disabled={isProductEmpty}
                  className="tw-flex tw-flex-row tw-items-center tw-w-fit tw-rounded-lg tw-py-[2px] tw-px-1 tw-text-sm tw-leading-[18px] disabled:tw-bg-[#E6E6E6] tw-transition-colors"
                  onClick={() => setIsSourcesOpen((prev) => !prev)}
                >
                  <ArrowIcon
                    className={`tw-fill-[#0844B4] tw-w-[24px] tw-h-[24px] tw-transition-transform ${
                      isSourcesOpen ? 'tw-rotate-[-90deg]' : 'tw-rotate-90'
                    }`}
                  />
                </Button>
                <Button
                  variant="text"
                  onClick={showDeleteProductModal}
                  className="tw-w-fit hover:tw-bg-transparent tw-p-0 "
                >
                  <DeleteIcon className="tw-fill-[#C12026] tw-min-w-6 tw-min-h-6" />
                </Button>
              </div>
            </td>
          </tr>
          <tr className={`tw-border-none tw-p-0`}>
            <td colSpan={12} className="tw-w-full tw-p-0">
              <Collapse in={isSourcesOpen && !isProductEmpty} className="tw-w-full" unmountOnExit>
                <div className="tw-flex tw-flex-col tw-w-full tw-pt-[9px] tw-pb-4 tw-bg-[#f7f7f7]">
                  <p className="tw-pl-[15px] tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-semibold">
                    Найдено {product?.sources?.length ?? 0} {getWordEnding1('источник', product?.sources?.length ?? 0)}
                  </p>
                  <div className="tw-pl-4 tw-mt-[69px] tw-mb-[17px]">
                    <PriceGraph
                      minPrice={product?.min_price}
                      avgPrice={product?.avg_price}
                      maxPrice={product?.max_price}
                    />
                  </div>
                  <SourcesList
                    sources={sourcesToDisplay}
                    asTable
                    product_id={product?.id}
                    product_name={product?.name}
                    selectedSources={product?.selected_sources}
                  />
                </div>
              </Collapse>
            </td>
          </tr>
        </>
      )}
    </>
  );
};
