import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Button from '../../../../components/button';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { ReactComponent as PhotoIcon } from '../../../../icons/photoIcon.svg';
import { useSelector } from 'react-redux';
import {
  canMakeScreenshots,
  getSelectedReportScreenshots,
  isScreenshotsInProgress,
} from '../../../../store/selectors/organization';
import { useCallback, useState } from 'react';
import { REPORT_SCREENSHOTS_STATUS } from '../../../../utils/constant';
import { createErrorNotification } from '../../../../utils/notifications';
import { useMakeScreenshotsMutation } from '../../../../services';

const getButtonText = (screenshots_status) => {
  switch (screenshots_status) {
    case REPORT_SCREENSHOTS_STATUS.PROCESSING:
    case REPORT_SCREENSHOTS_STATUS.FINISHED:
      return 'Скачать все скриншоты';
    case REPORT_SCREENSHOTS_STATUS.UPDATING:
      return 'Обновить все скриншоты';
    default:
      return 'Сделать скриншоты';
  }
};
// TODO: Не работает как надо, переделать скорее всего полностью
export const ScreenshotsMaker = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { id } = useParams();
  const user_id = useSelector((state) => state?.auth?.user)?.id;
  const screenshots = useSelector(getSelectedReportScreenshots);
  const canTakeScreenshots = useSelector(canMakeScreenshots);
  const screenShotsInProgress = useSelector(isScreenshotsInProgress);
  const [makeScreenshots] = useMakeScreenshotsMutation()

  const handleMakeScreenshots = useCallback(async () => {
    setIsDisabled(true);
    try {
      await makeScreenshots({project_id: id, user_id}).unwrap()
    } catch (err) {
      console.error('Error downloading ZIP:', err);
      createErrorNotification('Ошибка создания скриншотов');
    } finally {
      setIsDisabled(false);
    }
  }, [id, makeScreenshots, user_id]);

  const downloadScreenshots = useCallback(async () => {
    await downloadZipFile(`/api/screenshots/download/${id}`, `${id}.zip`).catch((err) => {
      createErrorNotification('Произошла ошибка при скачивании скриншотов')
    })
  }, [id])

  const buttonAction = screenshots?.status === REPORT_SCREENSHOTS_STATUS.FINISHED ? downloadScreenshots : handleMakeScreenshots

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-bg-white tw-rounded-lg tw-py-[11px] tw-px-3">
      <Button
        disabled={!canTakeScreenshots || isDisabled || screenShotsInProgress}
        onClick={buttonAction}
        variant="text"
        className="tw-w-fit disabled:tw-text-[#8CA6C1] tw-text-[#134B98] tw-flex tw-flex-row tw-items-center tw-gap-1 tw-p-0 hover:tw-bg-transparent"
      >
        <PhotoIcon />
        <p className=" tw-text-sm tw-font-medium tw-leading-[18px]">{getButtonText(screenshots?.status)}</p>
        <Tooltip
          title="Скриншоты страниц сайтов всех найденных 
источников и для всех товаров в проекте. 
Все скриншоты сохраняются в архивный 
файл zip формата"
        />
      </Button>

      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px]">Хранить 3 месяца</p>
    </div>
  );
};

// TODO: Временный костыль, не вышло подружить RTK с zip :(
export const downloadZipFile = async (path, name) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}${path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/zip',
        authorization: localStorage.getItem('token'),
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`);
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
    throw new Error('Error to download zip')
  }
};
